<template>
  <v-div>
    <!-- Max. Leistung -->
    <v-row class="mb-2">
      <v-col cols="12" md="4">
        <strong>Max. Leistung</strong><br />
        <span class="caption">in den ersten 4 Jahren</span>
      </v-col>
      <v-col cols="12" md="8">
        <div v-if="tarif.un_name">
          <strong>{{ tarif.tarif_int_begrenzung_2 }} | 1.-2. Jahr</strong><br />
          <strong>{{ tarif.tarif_int_begrenzung_4 }} | 3.-4. Jahr</strong>
        </div>
      </v-col>
    </v-row>
    <v-divider :thickness="1" class="my-2"></v-divider>

    <!-- Eckdaten -->
    <v-row class="mb-2">
      <v-col cols="12" md="4">
        <strong>Eckdaten</strong>
      </v-col>
      <v-col cols="12" md="8">
        <div v-if="tarif.un_name">
          Zahnersatz: <strong>{{ tarif.tarif_leistung_ze }}%</strong><br />
          Zahnerhalt: <strong>{{ tarif.tarif_leistung_zb }}%</strong><br />
          Prophylaxe: <strong>{{ tarif.tarif_leistung_pzr }}%</strong>
          <span
            v-if="tarif.tarif_leistung_pzr_ohne_begrenzung == 1"
            class="text-green"
          >
            | PZR-Flatrate</span
          >
          <span v-if="berechnung.ber_bleaching == 1 && tarif.tarif_beschreibung_bleaching">
           <br><b class="text-green">Bleaching:</b> <b>{{ tarif.tarif_beschreibung_bleaching }}</b>
            </span>
        </div>
      </v-col>
    </v-row>
    <v-divider :thickness="1" class="my-2"></v-divider>

    <!-- Wartezeiten -->
    <v-row class="mb-2">
      <v-col cols="12" md="4">
        <strong>Wartezeiten</strong>
      </v-col>
      <v-col cols="12" md="8">
        Zahnersatz: <strong>{{ tarif.tarif_beschreibung_wz_ze }}</strong
        ><br />
        Zahnerhalt: <strong>{{ tarif.tarif_beschreibung_wz_zb }}</strong>
      </v-col>
    </v-row>
    <v-divider :thickness="1" class="my-2"></v-divider>

    <!-- Hightech-Leistungen -->
    <v-row class="mb-2" v-if="berechnung.ber_algo_view != 2">
      <v-col cols="12" md="4">
        <strong>Hightech-Leistungen</strong>
      </v-col>
      <v-col cols="12" md="8">
        <ul v-if="tarif.highspezial">
          <li v-for="item in tarif.highspezial" :key="item.id">
            <span v-if="item.ja">{{ item.value }}</span>
            <span
              v-if="!item.ja"
              class="text-red"
              style="text-decoration: line-through"
              >{{ item.value }}</span
            >
          </li>
        </ul>
      </v-col>
    </v-row>
    <v-row class="mb-2" v-if="berechnung.ber_algo_view != 2">
      <v-col cols="12" md="4">
        <strong>Kinder-Leistungen</strong>
      </v-col>
      <v-col cols="12" md="8">
        <ul class="body-2">
          <li v-for="item in tarif.kindspezial" :key="item.id">
            <span v-if="item.ja">{{ item.value }}</span>
            <span
              v-if="!item.ja"
              class="text-red"
              style="text-decoration: line-through"
              >{{ item.value }}</span
            >
          </li>
        </ul>
      </v-col>
    </v-row>
    <v-divider :thickness="1" class="my-2"></v-divider>
  </v-div>
</template>

<script setup>
  import { defineProps } from "vue";
  // import { useBerechnungStore } from "@/stores/berechnung";
  // import { useSharedDataStore } from "@/stores/sharedData";
  // const sharedDataStore = useSharedDataStore();
  // const berechnungStore = useBerechnungStore();

  const props = defineProps({
    tarif: {
      type: Object,
      default: () => ({}),
    },
    berechnung: {
      type: Object,
      default: () => ({}),
    },
  });
  console.log(props.tarif);
</script>
